<template>
    
	<div class="grid dashboard">
            <div class="col-12 sm:col-12 md:col-12 lg:col-12"> 
                <div class="card overview-box flex flex-column pt-2 height-90"> 
                    <div class="flex justify-content-between mt-4 flex-wrap"> 
                       <h4>Dashboard</h4> 
                    </div>  
                </div> 
            </div> 
            
           
            
            
            
		<!-- <div class="col-12 sm:col-6 md:col-3 lg:col-3">
            <div class="card height-90">  
                <div class="card-header"> 
                        <h5 class="widget-statistics-heading-h5">Today Activity (100) </h5> 
                   </div>  
                <Divider />
                <div class="card-body">
                    <ul class="widget-list dashboard-employee-list">
                        <li class="flex align-items-center py-3">
                            <div class="person-item pointer flex align-items-center">
                                <img :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" /> 
                                <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                                    <div><strong>MMM KKKK</strong></div>
                                    <small class="muted-text">MBBS 4th year</small>
                                </div>
                            </div>
                            <span class="person-tag indigo-bgcolor p-1 fs-small" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">10:50:50</span> 
                        </li>  
                    </ul>
                    <div><h6 class="text-center pt-5 pb-5">No Employee Found! Create One</h6></div>
                </div> 
            </div>
        </div>  -->
	</div>
 </template>

<script>  


export default {
	data() {
		return { 
			applicationId:'',
			showAppStatus:false,
			selectedRecordId: 0,  

            hostels:[],
            leaveStatistics:[],
            loading: true,
		}
	}, 
	created() { 
	},
    components:{ 
        
    },
	mounted() { 
       
	},
	watch: { 
	},
	methods: {  
		closeForm(){ 
			this.showAppStatus = false;
		}, 
	},
	computed: { 
	}
}
</script>

<style lang="scss" scoped>

</style>
